import "./main.css"
import Alpine from "alpinejs"
import "htmx.org"
import htmx from "htmx.org"
import Chart from "chart.js/auto"
import { add, isBefore, format } from "date-fns";

const profileDropdownEls = document.querySelectorAll("#profile-locale-dropdown li > a")
if (profileDropdownEls.length) {
  for (const el of profileDropdownEls) {
    el.addEventListener("profile-locale-updated", function () {
      const dropdown = document.getElementById("profile-locale-dropdown")
      if (!dropdown) {
        return
      }
      dropdown.removeAttribute("open")
    })
  }
}

Alpine.data("copyButton", () => ({
  copied: false,
  onCopy: function (text: string) {
    navigator.clipboard.writeText(text)
    this.copied = true
  },
}))

Alpine.data("editableLabel", () => ({
  isEditMode: false,
  onSave: function (ev: Event) {
    const el = htmx.closest(ev.target as HTMLElement, "form")
    if (!el) {
      return
    }
    const trigger = el.getAttribute("hx-trigger") || ""
    htmx.trigger(el, trigger, {})
    this.isEditMode = false
  },
  onKeyDown: function (ev: KeyboardEvent) {
    if (ev.key === "Escape") {
      this.isEditMode = false
      ev.preventDefault()
    } else if (ev.key === "Enter") {
      this.onSave(ev)
      ev.preventDefault()
    }
  },
  setEditMode: function (ev: Event) {
    this.isEditMode = true
    setTimeout(() => {
      if (!ev.target) {
        return
      }
      const f = htmx.closest(ev.target as Element, "form")
      if (!f) {
        return
      }
      const input = f.querySelector("input[type='text']") as HTMLInputElement
      if (!input) {
        return
      }
      input.focus()
      input.select()
    }, 100)
  },
  resetEditMode: function () {
    this.isEditMode = false
  },
  onMouseDown: function (ev: MouseEvent) {
    ev.preventDefault()
  },
}))

Alpine.start()

function buildChart(id: string) {
  const chartEl = document.querySelector(id + " #chart") as HTMLCanvasElement
  if (!chartEl) {
    return
  }

  const chartData = JSON.parse(chartEl.getAttribute("data-results") || "[]")
  const now = new Date()
  const endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  const startDate = add(endDate, { days: -29 })
  const summary = []
  for (let i = 0; i < 30; i++) {
    const date = add(startDate, { days: i })
    summary.push({ startDate: date, passCount: null, failCount: null, errorCount: null, warnCount: null })
  }

  let dataIdx = 0
  let summaryIdx = 0
  while (dataIdx < chartData.length && summaryIdx < summary.length) {
    const dataDate = new Date(chartData[dataIdx].startDate)
    dataDate.setHours(0, 0, 0, 0)

    const summaryDate = summary[summaryIdx].startDate
    if (isBefore(dataDate, summaryDate)) {
      dataIdx++
      continue
    }
    if (isBefore(summaryDate, dataDate)) {
      summaryIdx++
      continue
    }
    summary[summaryIdx].passCount += chartData[dataIdx].passCount
    summary[summaryIdx].failCount += chartData[dataIdx].failCount
    summary[summaryIdx].errorCount += chartData[dataIdx].errorCount
    summary[summaryIdx].warnCount += chartData[dataIdx].warnCount
    dataIdx++
    summaryIdx++
  }

  new Chart(chartEl, {
    type: "bar",
    options: {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 5,
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
        },
      },
      datasets: {
        bar: {
          barPercentage: 1,
          categoryPercentage: 1,
        },
      },
    },
    data: {
      labels: summary.map((row) => row.startDate.toLocaleDateString()),
      datasets: [
        {
          label: "Pass",
          data: summary.map((row) => row.passCount),
          stack: "bar",
          backgroundColor: "rgba(0, 255, 0, 0.5)",
          borderColor: "rgba(0, 128, 0, 1)",
        },
        {
          label: "Fail",
          data: summary.map((row) => row.failCount),
          stack: "bar",
          backgroundColor: "rgba(255, 0, 0, 0.5)",
          borderColor: "rgba(128, 0, 0, 1)",
        },
        {
          label: "Error",
          data: summary.map((row) => row.failCount),
          stack: "bar",
          backgroundColor: "rgba(255, 0, 0, 0.75)",
          borderColor: "rgba(128, 0, 0, 1)",
        },
        {
          label: "Warn",
          data: summary.map((row) => row.warnCount),
          stack: "bar",
          backgroundColor: "rgba(255, 127, 0, 0.5)",
          borderColor: "rgba(128, 64, 0, 1)",
        },
      ],
    },
  })
}

if (document.getElementById("project-graph-container")) {
  htmx.on("#project-graph-container", "project:build-chart", function () {
    buildChart("#project-graph-container")
  })
}

if (document.getElementById("script-graph-container")) {
  htmx.on("#script-graph-container", "script:build-chart", function () {
    buildChart("#script-graph-container")
  })
}
